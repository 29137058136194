<template>
	<div>
		 <b-modal 
		 :id="$route.name + 'CreateModal'" 
		 title="Добавить категорию автомобиля" 
		 size="sm" 
		 hide-footer 
		 no-enforce-focus 
		 :no-close-on-backdrop="true" 
		 >
		<b-row class="mb-1">
			<b-col cols="12">
				<label for="">Имя категория<i style="color: red;">*</i></label>
				<b-input v-model="category.name" placeholder="Имя категория" />
			</b-col>
		</b-row>
		<b-row class="mb-1">
			<b-col cols="12">
				<label for="">Описание <i style="color: red;">*</i></label>
				<b-input v-model="category.description" placeholder="Описание" />
			</b-col>
		</b-row>
		<b-row class="mb-1">
			<b-col cols="12">
				<b-form-checkbox v-model="category.is_active" checked="true" name="check-button" switch inline>
					{{ this.category.is_active ? 'Активный' : 'Не активный' }}
				</b-form-checkbox>
			</b-col>
		</b-row>
		<b-row class="mb-1 mt-2">
			<b-col class="d-flex justify-content-end">
				<b-button :disabled="submitButtonDisabled || !category.name" @click="createCategory()" variant="primary">Добавить</b-button>
				<b-button @click="closeModal()" class="ml-1" variant="danger">Отмена</b-button>
			</b-col>
		</b-row>
		 </b-modal>
	</div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
	component: {
		 ToastificationContent,
	},
	data(){
		 return {
			category: {
				name: null,
				description: null,
				is_active: false
			},
			submitButtonDisabled: false,
		 }
	},
	methods: {
		 closeModal() {
			this.category = {
				name: null,
				description: null,
				is_active: false
			},
			this.submitButtonDisabled = false
			this.$bvModal.hide(this.$route.name + 'CreateModal')
		 },
		 createCategory() {
			this.category.is_active = this.category.is_active == true ? 1 : 0
			this.submitButtonDisabled = true
			this.$http.post('car-settings/categories', this.category)
			.then(() => {
				this.closeModal()
				this.$emit('refresh')
				this.$toast({
						component: ToastificationContent,
						position: 'top-right',
						props: {
							title: 'Категория добавлена!',
							icon: 'CheckIcon',
							variant: 'success',
						},
				})
			})
			.catch(err => {
				this.$toast({
						component: ToastificationContent,
						position: 'top-right',
						props: {
							title: 'Некорректные данные!',
							icon: 'XIcon',
							variant: 'danger',
							text: err.response.data.errors,
						},
				})
			})
			.finally(() => {
				this.submitButtonDisabled = false
			})
		 },
		 
	}
}
</script>