<template>
	<b-modal 
	id="editCategory" 
	title="Редактирование категория автомобиля" 
	size="sm" 
	@shown="getCategory"
	hide-footer 
	no-enforce-focus 
	:no-close-on-backdrop="true"
	>
	<b-row class="mb-1">
		<b-col cols="12">
			<label for="">Категория</label>
			<b-input v-model="category.name" placeholder="Описание" />
		</b-col>
	</b-row>
	<b-row class="mb-1">
		<b-col cols="12">
			<label for="">Описание</label>
			<b-input v-model="category.description" placeholder="Описание" />
		</b-col>
	</b-row>
	<b-col cols="12">
		<b-form-checkbox v-model="category.is_active" checked="true" name="check-button" switch inline>
			{{ this.category.is_active ? 'Активный' : 'Не активный' }}
		</b-form-checkbox>
	</b-col>
	<b-row class="mb-1 mt-2">
		<b-col class="d-flex justify-content-end">
			<b-button :disabled="submitButtonDisabled || !category.name" @click="update" variant="primary">
			Изменить
			</b-button>
			<b-button @click="closeModal" class="ml-1" variant="danger">Отмена</b-button>
		</b-col>
	</b-row>
	</b-modal>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
	components: {
		 ToastificationContent,  
	},
	props: {
		id: {
			type: Number,
			default: null,
		},
		},
	data(){
		 return {
			category: {
					id: '',
					name: '',
					is_active: '',
					description: ''
			  },
			  submitButtonDisabled: false,
		 }
	},
	methods: {
		 closeModal() {
			this.category.name = null;
			this.submitButtonDisabled = false;
			this.$bvModal.hide('editCategory');
		 },
		 getCategory() {
			this.$http
				.get(`car-settings/categories/${this.id}/edit`)
				.then((res) => {
				this.category = res.data;
				this.category.is_active = res.data.is_active === 1 ? true : false;
				})
				.catch((err) => {
				this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Ошибка!',
						icon: 'XIcon',
						variant: 'danger',
						text: 'Не удалось загрузить категорию!',
					},
				});
				});
		},
		update() {
			this.submitButtonDisabled = true;
			this.category.is_active = this.category.is_active ? 1 : 0;
			this.$http
				.patch(`car-settings/categories/${this.id}`, this.category)
				.then((res) => {
				this.closeModal();
				this.$emit('refresh');
				this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Категория изменена!',
						icon: 'CheckIcon',
						variant: 'success',
						text: res.data.message,
					},
				});
				})
				.catch((err) => {
				this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Ошибка!',
						icon: 'XIcon',
						variant: 'danger',
						text: 'Некорректные данные',
					},
				});
				})
				.finally(() => {
					this.submitButtonDisabled = false;
				});
		},
		},
	};
	</script>