<template>
	<div>
	  <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
		 <b-spinner variant="primary" label="Text Centered" />
	  </div>
	  <div v-else>
		 <table-car-categories :categories="categories" @editForm="editForm"/>
	  </div>
	  <modal-car-categories @refresh="refresh" />
	  <modal-car-categories-edit :id="id" @refresh="refresh" />
	  <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
	</div>
 </template>
 <script>
 import ModalCarCategories from '@/views/component/Modal/ModalCategoriesCar/ModalCarCategories.vue'
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import ModalCarCategoriesEdit from '@/views/component/Modal/ModalCategoriesCar/ModalCarCategoriesEdit.vue'
 import tableCarCategories from '@/views/component/Table/tableCarCategories.vue'
 export default {
	components: {
		ModalCarCategories,
		ToastificationContent,
		ModalCarCategoriesEdit,
	  	tableCarCategories
	},
	data() {
	  return {
		  	showPreloader: false,
		  	categories: [],
			id: undefined,
			fields: [
				{ key: 'id', label: 'ID' },
				{ key: 'name', label: 'Name' },
				{ key: 'date_time', label: 'Создан' },
			],
	  }
	},
	methods: {
	refresh() {
		this.showPreloader = true
			this.$http
				.get('car-settings/categories')
				.then(res => {
				this.categories = res.data
				this.showPreloader = false
			})
      	.catch(err => {
			this.$toast({
			component: ToastificationContent,
			position: 'top-right',
			props: {
				title: 'Ошибка!',
				icon: 'XIcon',
				variant: 'danger',
				text: err.response.data.errors,
			},
			})
      	})
    },
		editForm(id) {
			this.id = id
			this.$bvModal.show('editCategory')
	  },
	  openFilter(){
		 let allRoutes = this.$store.state.draggableTab.tabs
		 let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
		 let arrayRoutes = []
		 allRoutes.forEach(element => {
			arrayRoutes.push(element.path)
		 });
		 if (arrayRoutes.includes(this.$route.path) == true) {
			this.showPreloader = true
			this.$http
			.get(`${this.$route.name}`, {params: param}).then(res => {
			  this.categories = res.data
			  this.showPreloader = false
			  this.$store.commit('REFRESH_DATA', false)
			})
		 }else if(arrayRoutes.includes(this.$route.path) == false){
			this.$bvModal.show(this.$route.name + 'filter')
		 }
	  },
	  
	  sendToParent(tableData){
		 this.categories = tableData
	  },
	},
	mounted() {
		 this.openFilter()
  		},
	computed: {
	  fetchingNewData(){
		 return this.$store.state.refresh.fetchingNewData
	  }
	},
	watch: {
	  fetchingNewData(val){
		   let params = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
		 	if (val){
			this.$http
			.get(`${this.$route.name}`, {params: params})
			.then(res =>{
			  this.categories = res.data
			  this.$store.commit('pageData/setdataCount', this.categories.length)
			  this.$store.commit('REFRESH_DATA', false)
			})
		 }
	  }
	}
 }
 </script>